<template>
  <CChartBar
    :datasets="defaultDatasets"
    :labels="label"
    :options="{
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
    }"
  />
</template>

<script>
import { CChartBar } from "@coreui/vue-chartjs";

export default {
  props: ["jobChart", "enterprisesChart"],
  name: "CChartBarExample",
  components: { CChartBar },
  data() {
    return {
      title: null,
      label: [],
      chartData: [],
    };
  },
  mounted() {

    if (this.jobChart) {

      this.chartData = this.jobChart.map((job) => {
        let totalAchieve = [];
        let finalTotal = 0;
        job.target.forEach((element) => {
          let Achived = element.archived || 0;
          let Element = element.number || 0;
          let value1 = Achived * 100;;
          totalAchieve.push(value1 / Element);
        });
        totalAchieve.forEach((element) => {
          finalTotal += element;
        });
        return finalTotal / totalAchieve.length;
      });
      this.title = "Achieved";
      this.label = this.jobChart.map((jobChart) => jobChart.header);
    }
    if (this.enterprisesChart) {
      this.chartData = this.enterprisesChart.map((enterprise) =>
        parseInt(enterprise.accomplishment.enterprises)
      );
      this.title = "enterprises";
      this.label = this.enterprisesChart.map((enterprise) => enterprise.header);
    }
  },
  computed: {
    defaultDatasets() {
      return [
        {
          label: this.title,
          backgroundColor: "#3c5887",
          data: this.chartData,
        },
      ];
    },
  },
};
</script>
