var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CChartBar',{attrs:{"datasets":_vm.defaultDatasets,"labels":_vm.label,"options":{
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        } ],
    },
  }}})}
var staticRenderFns = []

export { render, staticRenderFns }