<template>
  <div>
    <div v-if="jobChart" class="text-center small">
      
      {{"Target: "+Intl.NumberFormat('en-US').format(jobChart.number) + " Achieved:"}} <span v-if="jobChart.archived">{{Intl.NumberFormat('en-US').format(jobChart.archived)}}</span><span v-if="!jobChart.archived">0</span>
    </div>
    <div v-if="enterprisesChart" class="text-center small">
      {{"Target :"+ enterprisesChart.target.enterprises + " Achieved:" +enterprisesChart.accomplishment.enterprises}}
    </div>
    
  <!-- {{jobChart.target.jobs[0] + " " +jobChart.target.jobs[1]}} -->
  <CChartDoughnut
    :datasets="defaultDatasets"
    :labels="['Remaining', 'Achieved']"
  />
  </div>
  
</template>

<script>
import { CChartDoughnut } from '@coreui/vue-chartjs'

export default {
  props:['jobChart','enterprisesChart'],
  name: 'CChartDoughnutExample',
  components: { CChartDoughnut },
  data() {
    return {
      chartData:[0,0],
    }
  },
  mounted() {
    if(this.jobChart){
       let job = this.jobChart.archived || 0;
       let target = this.jobChart.number - job;
       if(target < 0){
         target = 0
       }
       this.$set(this.chartData, 0, parseInt(target))

       this.$set(this.chartData, 1, parseInt(job))
    }
    if(this.enterprisesChart){
        let enterprises = this.enterprisesChart.accomplishment.enterprises;
       let target = this.enterprisesChart.target.enterprises - this.enterprisesChart.accomplishment.enterprises ;
 this.$set(this.chartData, 0, parseInt(target))
       this.$set(this.chartData, 1, parseInt(enterprises))
    }
  },
  computed: {
    defaultDatasets () {
      return [
        {
          backgroundColor: [
            '#f1f1f1',
            '#3c5887',
            
          ],
          data: this.chartData
        }
      ]
    }
  }
}
</script>
